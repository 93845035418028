/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
require.context('../../src/assets/images', true, /\.(png|jpg|jpeg|svg|gif)$/)
// var componentRequireContext = require.context("../../src/components/ats", true)
require('jquery')

$(function(){
  // Common
  if ($('.js-header').length && $('.js-header-button').length) {
    $('.js-header-button').on('click.headerToggle', function() {
      $('.js-header').toggleClass('is-active');
    });
  }

  if ($('.js-contact-button').length) {
    var $el = $('.js-contact-button');
    var timer = null;
    var windowHeight = window.innerHeight;

    $(window).on('scroll.contactButton', function() {
      clearTimeout(timer);
      timer = setTimeout(function() {
        if ($(window).scrollTop() > windowHeight / 2) {
          $el.addClass('is-visible');
        } else {
          $el.removeClass('is-visible');
        }
      }, 250);
    });
  }

  // Index
  if ($('.js-scrollDown-button').length && $('.js-scrollDown-contents').length) {
    console.log('index');
    $('.js-scrollDown-button').on('click.scrollDown',function() {
      var offset = $('.js-scrollDown-contents').offset().top;
      $('html,body').animate({scrollTop: offset}, 200);
    });
  }

  if ($('.swiper-container').length) {
    const swiper = new Swiper('.swiper-container', {
      allowTouchMove: false,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          speed: 2000,
        }
      },
      centeredSlides: true,
      freeMode: {
        enabled: true,
        momentum: false,
      },
      loop: true,
      loopedSlides: 14,
      simulateTouch: false,
      slidesPerView: 'auto',
      spaceBetween: 0,
      speed: 3000,
      watchSlidesProgress: true
    });
  }

  // Features
  if ($('.js-modal-button').length) {
    console.log('features');
    $('.js-modal-button').on('click.modalOpen', function(e) {
      e.preventDefault();
      var slug = $(this).attr('href');
      if ($(slug).length) {
        $(slug).addClass('is-active');
      }
    });
  }

  if ($('.js-modal-close').length) {
    $('.js-modal-close').on('click.modalClose', function() {
      var $target = $(this).parents('.js-modal');
      $target.removeClass('is-active');
    });
  }

  // Pricing
  if ($('.js-pricing-button-open').length) {
    $('.js-pricing-button-open').on('click.pricingPanelOpen', function() {
      var $target = $(this).parents('.js-pricing-content');
      if ($target.length) {
        $target.addClass('is-open');
      }
    });
  }

  if ($('.js-pricing-button-close').length) {
    $('.js-pricing-button-close').on('click.pricingPanelClose', function() {
      var $target = $(this).parents('.js-pricing-content');
      if ($target.length) {
        $target.removeClass('is-open');
        $('html,body').animate({scrollTop: $target.offset().top}, 400);
      }
    });
  }

  // FAQ
  if ($('.js-faq-category').length) {
    $('.js-faq-category').each(function() {
      var id = $(this).attr('id');
      var count = $(this).find('.corp-p-faqDetail__item').length;
      if ($('#category_'+id).length) {
        $('#category_'+id).find('.js-category-count').text(count);
      }
    });
  }

  if ($('.js-anchorScroll').length) {
    $('.js-anchorScroll').on('click.anchorScroll', function() {
      var targetEl = $(this).find('a').attr('href');
      if ($(targetEl)) {
        $('html,body').animate({scrollTop: $(targetEl).offset().top - 150}, 300);
      }
    });
  }

  let params = new URLSearchParams(location.search);
  let q = parseInt(params.get('q'));
  if (q) {
    var $target = $('[data-faq-id="'+q+'"]');
    if ($('[data-faq-id="'+q+'"]').length) {
      $('html,body').animate({scrollTop: $target.offset().top - 150}, 300);
      $target.find('input').prop('checked',true);
    }
  }

  // Timeline
  if ($('.js-category-list-mobile').length) {
    var path = window.location.pathname;
    if ($('.js-category-list-mobile').find('option[value="'+path+'"]')) {
      $('.js-category-list-mobile').find('option[value="'+path+'"]').prop('selected', true);
    }
  }
});
